import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Image } from 'react-bootstrap';
import { PreviewNews } from './PreviewNews';
import { getHighlightedDocumentsAPI } from '../hooks/documents';

export const LastNews = () => {
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
      getHighlightedDocuments();
    }, [])

    const getHighlightedDocuments = async () => {
      const resp = await getHighlightedDocumentsAPI();
      setDocuments( resp );
    }

    const listNews = documents && documents.map((n, index) =>
      <Col sm={12} md={6} lg={4} xl={3} key={index}>
        <PreviewNews data={n} />
      </Col>
        );
    return (
        <div>
            <Row>
            {listNews}
            </Row>
        </div>
    )
}

