import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Form, Container, Button, Alert } from 'react-bootstrap';
import { sendContactFormAPI } from '../hooks/contactForm';

export const ContactForm = () => {
    const [errors, setErrors] = useState([]);
    const [formSent, setFormSent] = useState(false);
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        area: '11',
        phone: '',
        locale: '',
        comment: ''
    });

    const handleChange = (e) => {
        setUserData({
          ...userData,
          [e.target.name]: e.target.value,
        })
    }

    const sendForm = async () => {
        const params = {
            name: userData.name,
            email: userData.email,
            area: userData.area,
            phone: userData.phone,
            locale: userData.locale,
            comment: userData.comment
        };
        const resp =  await sendContactFormAPI(params);
        setErrors(resp.errors);
        if( resp.success ){
            setFormSent(true);
        }
    };

    const showErrors = errors.map((error, index) => 
        <div key={index}>
            <Alert key="danger" variant="danger">
                {error}
            </Alert>
        </div>
    )

    return (
        <>
            <Container className="contact-form">
                <Row>
                    <Col sm={8}>
                        <h1 id="form">Contacto</h1>
                        {formSent ? 
                        <>
                            <div className="mb-3">
                                Hemos recibido su consulta. En breve nos pondremos en contacto con usted
                            </div>
                        </>
                        :
                        <>
                            <div className="mb-3">
                                Dejanos tu consulta y nos comunicaremos en breve
                            </div>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control type="text" name='name' onChange={handleChange} value={userData.name} placeholder="Nombre Completo" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                        <Form.Control type="email" name='email' onChange={handleChange} value={userData.email} placeholder="Email" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                        <Row>
                                            <Col  xs={4}>
                                                <Form.Label>Código de área</Form.Label>
                                                <Form.Control type="text" name='area' onChange={handleChange} value={userData.area} placeholder="Cod.Area" />
                                            </Col>
                                            <Col xs={8}>
                                                <Form.Label>Número de teléfono si el 15</Form.Label>
                                                <Form.Control type="text" name='phone' onChange={handleChange} value={userData.phone} placeholder="Teléfono sin el 15" />
                                            </Col>
                                            <Col  xs={12} style={{fontSize: "0.9rem", color: "#898989"}}>
                                                Ingrese el Código de área sin el 0 y el teléfono sin el 15
                                            </Col>

                                        </Row>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                        <Form.Control type="text" name='locale' onChange={handleChange} value={userData.locale} placeholder="Localidad" />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" rows={5} name='comment' onChange={handleChange} value={userData.comment}    placeholder="Dejenos su consulta ( hasta 250 caracteres)" />
                                    </Form.Group>
                                    {showErrors}
                                    <Button variant="primary" type="button" onClick={() => {sendForm()}}>
                                        Enviar
                                    </Button>
                                </Col>
                            </Row>
                        </>
                        }
                    </Col>
                    {false && 
                        <Col sm={4}>
                            <h1>Newsletter</h1>
                            <div className="mb-3">
                                &nbsp;
                            </div>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Control type="email" placeholder="Email" />
                            </Form.Group>
                        </Col>
                    }
                </Row>
            </Container>
        </>
    )
}