/* FUENTES: 
https://www.youtube.com/watch?v=c0MpQeOKc-4
https://www.youtube.com/watch?v=k68j9xlbHHk
*/
import {configureStore} from '@reduxjs/toolkit'
import cart from './slices/cart';

export default configureStore({
    reducer: {
        cart
    }
});