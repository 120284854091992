import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        detail: {},
        articles: [],
        sections: [],
        categories: [
            {id: '1', name: "Vegetación", selected: false},
            {id: '2', name: "Floración", selected: false}
        ],
    },
    reducers: {
        setCart: (state, action) => {
            state.detail = action.payload; // payload trae el contenido de la API
        },
        setSections: (state, action) => {
            state.sections = action.payload; // payload trae el contenido de la API
        },
        setCategories: (state, action) => {
            state.categories = action.payload; // payload trae el contenido de la API
        },
        setArticles: (state, action) => {
            state.articles = action.payload; // payload trae el contenido de la API
        }
    }
});

export const { setCart, setSections, setCategories, setArticles } = cartSlice.actions;

export default cartSlice.reducer;

export const fetchCart = (cookieId) => (dispatch) => {
    try{
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        let params = new URLSearchParams();
        params.append('cookie_value', cookieId);
        axios
            .post('cart/details.php', params)
            .then((response) => {
                dispatch(setCart(response.data));        
            })
            .catch((error) => console.log(error));

    } catch (e) {
        console.log(e)
    }    
}

export const addToCart = (cookieId, articleId, quantity) => (dispatch) => {
    try{
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        let params = new URLSearchParams();
        params.append('cookie_value', cookieId);
        params.append('id', articleId);
        params.append('quantity', quantity);
        axios
            .post('cart/add.php', params)
            .then((response) => {
                dispatch(setCart(response.data));        
            })
            .catch((error) => console.log(error));

    } catch (e) {
        console.log(e)
    }    
}

export const removeToCart = (cookieId, articleId) => (dispatch) => {
    try{
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        let params = new URLSearchParams();
        params.append('cookie_value', cookieId);
        params.append('id', articleId);
        axios
            .post('cart/remove.php', params)
            .then((response) => {
                dispatch(setCart(response.data));        
            })
            .catch((error) => console.log(error));

    } catch (e) {
        console.log(e)
    }    
}