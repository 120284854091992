import React, {useEffect, useState} from 'react';
import { Link, useParams, Redirect } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Image, Table, Button, Row, Col, Form, Alert, Card } from 'react-bootstrap';
import logo from '../img/weeding-planner-logo-web.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { completedPurchasesAPI } from '../hooks/cart';
import { PaymentMethods } from '../components/cart/PaymentMethods';
import { CartDetails } from '../components/CartDetails';

export const PurchaseClient = () => {
    const {id} = useParams();
    const [detail, setDetail] = useState({});

    useEffect(() => {
        completedPurchase();
    }, [])
  
    const completedPurchase = async () => {
        const resp =  await completedPurchasesAPI(id);
        setDetail(resp);
    };

   return (
        <>
            {id == 1 &&
                <Redirect to='/shop' />
            }
            <Container fluid>
                <div className="row single-header mb-3">
                    <Navbar className="align-items-start">
                        <Link to="/" className='navbar-brand'>
                            <Image src={logo} width={250} className="d-inline-block align-top" fluid />
                        </Link>
                        <Navbar.Toggle />
                        <Navbar.Collapse className="justify-content-end">
                        </Navbar.Collapse>
                        <CartDetails />
                    </Navbar>
                </div>
                <Container className="article">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                            <th>Cantidad</th>
                            <th>Producto</th>
                            <th>Precio unitario</th>
                            <th>Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                detail.articles && detail.articles.map((article, index) =>
                                    <tr key={index}>
                                        <td>
                                            <div>
                                                {article.cantidad}
                                            </div>
                                        </td>
                                        <td>
                                            <Link className="btn btn-outline-success" to={`/article/${article.articulo_id}`} >{article.nombre}</Link>
                                        </td>
                                        <td>$ {article.precio}</td>
                                        <td>$ {article.cantidad * article.precio}</td>
                                    </tr>
                                )
                            }
                            {
                                (detail.articles && detail.articles.length > 0) &&
                                <tr>
                                    <td colSpan={2}>
                                        <b>Nombre:</b> {detail.purchase.user.nombre}<br/>
                                        <b>Email:</b> {detail.purchase.user.email}<br/>
                                        <b>Teléfono:</b> {detail.purchase.user.telefono}<br/>
                                        <b>Comentario:</b> {detail.purchase.user.comentario}<br/>
                                    </td>
                                    <td className='text-end' ><b>Total</b></td>
                                    <td ><b>$ {detail.purchase.total}</b></td>
                                </tr>
                            }
                        </tbody>
                    </Table>

                    {(detail.articles && detail.articles.length > 0) && 
                        <PaymentMethods purchase={detail.purchase} />
                    }

                </Container>
            </Container>        
        </>
    )
}