import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Image, Row } from 'react-bootstrap';
import logo from '../img/weeding-planner-logo-web.png';
import btnVegetacion from '../img/vegetacion-text.png';
import btnFloracion from '../img/floracion-text.png';

export const HomeMobile = () => {
    const [isVegetacion, setVegetacion] = useState("true");

    return (
        <>
            <div className={`header-home-mobile d-block d-sm-none ${isVegetacion ? "header-home-mobile-el" : "header-home-mobile-ella"}`} style={{ minHeight: '100vh' }}>
                <div className="row justify-content-left" style={{ height: '25vh' }}>
                    <div className="col-5 d-flex align-items-top logo-mobile" style={{ border: '0px solid' }}>
                    </div>
                </div>
                <div className="row justify-content-center" style={{ height: '75vh' }}>
                    <div className="col-6 align-items-top justify-content-center align-top" style={{ border: '0px solid' }}>
                        <button className={`btn btn-default ${isVegetacion ? "btnSelected" : ""}`} style={{ background: '#00C953' }} onClick={(e) => setVegetacion(true)}>
                            <Image src={btnVegetacion} fluid />
                        </button>
                    </div>
                    <div className="col-6 align-items-top justify-content-center align-top" style={{ border: '0px solid' }}>
                        <button className={`btn btn-default ${isVegetacion ? "" : "btnSelected"}`} style={{ background: '#FF43C0' }} onClick={(e) => setVegetacion(false)}>
                            <Image src={btnFloracion} fluid />
                        </button>
                    </div>
                </div>

                { false && (
                <div className="row justify-content-center" style={{ height: '70vh' }}>
                    <div className="col-6 d-flex align-items-top justify-content-center align-top" style={{ border: '0px solid' }}>
                        <div style={{ padding: '40px 10%' }}>
                            <Image src={logo} fluid />
                        </div>
                    </div>
                    <div className="col-6 d-flex align-items-center" style={{ border: '0px solid' }}>
                        
                    </div>
                </div>
                )}
            </div>
        </>
    )
}