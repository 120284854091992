import React, {useEffect, useState} from 'react';
import { Link, Redirect } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Image, Table, Button, Row, Col, Form, Alert, Card } from 'react-bootstrap';
import logo from '../img/weeding-planner-logo-web.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useCookies } from 'react-cookie';
import { finishCartAPI } from '../hooks/cart';
import { CartDetails } from '../components/CartDetails';
//redux
import { fetchCart, addToCart, removeToCart } from '../store/slices/cart';
import { useDispatch, useSelector } from 'react-redux';

export const PurchaseDetail = () => {
    const {detail} = useSelector(state => state.cart);
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['cookieId']);
    const [cartId, setCartId] = useState("");

    const [buySteps, setBuySteps] = useState(1);
    const [userData, setUserData] = useState({
        name: '',
        email: '',
        area: 11,
        phone: '',
        comment: ''
    });

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        dispatch(fetchCart(cookies.cookieId))
    }, [])
  
    const finishCart = async () => {
        const params = {
            cookieId: cookies.cookieId,
            name: userData.name,
            email: userData.email,
            area: userData.area,
            phone: userData.phone,
            comment: userData.comment
        };
        const resp =  await finishCartAPI(params);
        setErrors(resp.errors);
        if( resp.success ){
            setCartId(resp.cart_id)
            setCookie("cookieId", resp.new_cookie, {
                path: "/",
                expires: new Date(new Date().getTime() + (12*60*60*1000)), // 12 hours
              });
            setBuySteps(3)
        }
    };

    const showErrors = errors && errors.map((error, index) => 
        <div key={index}>
            <Alert key="danger" variant="danger">
                {error}
            </Alert>
        </div>
    )

    const subtractCart = (e, article) => {
        if(article.cantidad > 1){
            dispatch(addToCart(cookies.cookieId, article.articulo_id, parseInt(article.cantidad) - 1));
        }
        e.preventDefault();
    }

    const sumCart = (e, article) => {
        dispatch(addToCart(cookies.cookieId, article.articulo_id, parseInt(article.cantidad) + 1));
        //setQuantity(quantity + 1)
        e.preventDefault();
    } 
    
    const confirmRemove = (e, article) => {
        if(window.confirm("Está seguro que quiere eliminar este producto ?")){
            dispatch(removeToCart(cookies.cookieId, article.articulo_id));
        }
        e.preventDefault();
    }

    const handleChange = (e) => {
        setUserData({
          ...userData,
          [e.target.name]: e.target.value,
        })
    }

    return (
        <>
            <Container fluid>
                <div className="row single-header mb-3">
                    <Navbar className="align-items-start">
                        <Link to="/" className='navbar-brand'>
                            <Image src={logo} width={250} className="d-inline-block align-top" fluid />
                        </Link>
                        <Navbar.Toggle />
                        <CartDetails />
                    </Navbar>
                </div>
                <Container className="article">
                    {(buySteps == 1) && 
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th>Cantidad</th>
                                <th>Producto</th>
                                <th>Precio unitario</th>
                                <th>Precio</th>
                                <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    detail.articles && detail.articles.map((article, index) =>
                                        <tr key={index}>
                                            <td>
                                                <div className="buy">
                                                    <a href="#" className="increase-decrease" onClick={(e) => {subtractCart(e, article)}} >-</a> 
                                                    &nbsp;<input type="text" value={article.cantidad} disabled />&nbsp;
                                                    <a href="#" className="increase-decrease" onClick={(e) => {sumCart(e,article)}} >+</a>
                                                </div>
                                            </td>
                                            <td>
                                                <Link className="btn btn-outline-success" to={`/article/${article.articulo_id}`} >{article.nombre}</Link>
                                            </td>
                                            <td>$ {article.precio}</td>
                                            <td>$ {article.cantidad * article.precio}</td>
                                            <td>
                                                <div className="buy">
                                                    <a href="#" className="increase-decrease" onClick={(e) => {confirmRemove(e,article)}} >Eliminar</a>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                                {
                                    (detail.articles && detail.articles.length > 0) &&
                                    <tr>
                                        <td className='text-end' colSpan={3}><b>Total</b></td>
                                        <td colSpan={2}><b>$ {detail.purchase.total}</b></td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    }
                    {(buySteps == 2) && 
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control name="name" type="text" onChange={handleChange} value={userData.name} placeholder="Nombre Completo" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                    <Form.Control type="email" name="email" onChange={handleChange} value={userData.email} placeholder="Email" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                    <Row>
                                        <Col  xs={4}>
                                            <Form.Label>Código de área</Form.Label>
                                            <Form.Control type="text" name='area' onChange={handleChange} value={userData.area} placeholder="Cod.Area" />
                                        </Col>
                                        <Col xs={8}>
                                            <Form.Label>Número de teléfono si el 15</Form.Label>
                                            <Form.Control type="text" name='phone' onChange={handleChange} value={userData.phone} placeholder="Teléfono sin el 15" />
                                        </Col>
                                        <Col  xs={12} style={{fontSize: "0.9rem", color: "#898989"}}>
                                            Ingrese el Código de área sin el 0 y el teléfono sin el 15
                                        </Col>

                                    </Row>
                                </Form.Group>

                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows={5} name="comment" onChange={handleChange} value={userData.comment} placeholder="Dejanos un comentario" />
                                </Form.Group>
                                {showErrors}
                                <Button variant="primary" type="button" onClick={() => {finishCart()}}>
                                    Enviar pedido y pagar
                                </Button>                                
                            </Col>
                        </Row>
                    }

                    {(buySteps == 3) && 
                        <>
                            
                            <h3>Le hemos enviado un email con información sobre su compra.</h3>
                            <h4>En caso de no verlo en bandeja de entrada, verifique en correo no deseado o spam.</h4>
                            <Link className="btn btn-success" to={"/purchase/"+cartId} >Pagar</Link>
                        </>
                    }
                    {(buySteps < 3 && detail.articles && detail.articles.length > 0) &&
                    <>
                        <Button variant="primary" type="button" onClick={() => {setBuySteps(2)}}>
                            {buySteps == 1 ? 'Realizar el pago' : 'Volver'}
                        </Button>
                        &nbsp;Envíos a CABA y GBA a coordinar
                    </>
                    }
                </Container>
            </Container>        
        </>
    )
}