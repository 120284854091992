import Reactt, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { PreviewProduct } from './PreviewProduct';
// redux
import { useSelector } from 'react-redux';

export const ProductsList = () => {
  const {sections, categories, articles} = useSelector(state => state.cart);
  /*
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    getArticles();
  }, [])

  const getArticles = async () => {
      const resp = await getArticlesAPI();
      setArticles( resp );
  }
  */

  const productsHome = articles && articles.map((article, index) =>{
    const rubrosArticulo = article.rubros.map(v => v.id);
    const variantesArticulo = article.variantes.map(v => v.id);

    const selectedRubros = sections.filter(v => v.selected).map(v => v.id);
    const selectedVariantes = categories.filter(v => v.selected).map(v => v.id);

    const articleHasRubro = rubrosArticulo.map(v => selectedRubros.indexOf(v)).filter(v => v > -1).length > 0
    const articleHasVariante = variantesArticulo.map(v => selectedVariantes.indexOf(v)).filter(v => v > -1).length > 0

    const condition = (selectedRubros.length == 0 && selectedVariantes.length == 0) || 
      (articleHasRubro && selectedVariantes.length == 0) ||
      (articleHasVariante && selectedRubros.length == 0) ||
      (articleHasRubro && articleHasVariante);

    if(condition)
      return <Col md={6} lg={4} key={index}>
        <PreviewProduct data={article} />
      </Col>
    
  });
  return (
      <div>
          <Row>
          {productsHome}
          </Row>
      </div>
  )
}

