import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav } from 'react-bootstrap';
import { setCart, fetchCart } from '../store/slices/cart';
import { useDispatch, useSelector } from 'react-redux';

export const CartDetails = () => {
    const {detail} = useSelector(state => state.cart);
    const dispatch = useDispatch();

    const [cookies] = useCookies(['cookieId']);

    useEffect(() => {
        dispatch(fetchCart(cookies.cookieId));
    }, [])

    return (
        <>
            <Navbar.Collapse className="justify-content-end">
                <Nav>
                    <Link className='btn btn-success' to="/home">Inicio</Link>&nbsp;
                    <Link className='btn btn-success' to="/shop">Tienda</Link>&nbsp;
                    <Link className='btn btn-success' to="/home#form">Contacto</Link>&nbsp;
                    { detail.purchase && detail.purchase.total > 0 &&
                        <Link className='btn btn-success' to="/purchase_detail">Ver compra ($ {detail.purchase.total})</Link>
                    }

                </Nav>
            </Navbar.Collapse>
        </>
    )
}

