import React, {useState, useEffect} from 'react';
import { useCookies } from 'react-cookie';
import { checkCartIdAPI } from '../hooks/cart';

export const CheckCart = () => {
    const [cookies, setCookie] = useCookies(['cookieId']);
    useEffect(() => {
        verifyCart();
    }, [])

    const verifyCart = async () => {
        const resp = await checkCartIdAPI(cookies.cookieId);

        if(cookies.cookieId && cookies.cookieId === resp.hash){
            //console.log("La cookie existe y vale", cookies.cookieId)
        } else {
            setCookie("cookieId", resp.hash, {
                path: "/",
                expires: new Date(new Date().getTime() + (12*60*60*1000)), // 12 hours
                //expires: new Date(new Date().getTime() + (20*1000)), // 10 secs
              });
        }
    }

    return (
        <div>
        </div>
    )
}

