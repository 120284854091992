import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Form } from 'react-bootstrap';
import StarRatings from 'react-star-ratings';
import { useCookies } from 'react-cookie';
//redux
import { addToCart } from '../store/slices/cart';
import { useDispatch, useSelector } from 'react-redux';

export const PreviewProduct = (props) => {
    const {detail} = useSelector(state => state.cart);
    const dispatch = useDispatch();

    const [cookies] = useCookies(['cookieId']);
    const [quantity, setQuantity] = useState(1);
    const [wasBought, setWasBought] = useState(false);

    useEffect(() => {
        if(detail.articles && detail.articles.length > 0){
            const q = detail.articles.filter(article => article.articulo_id == props.data.id)[0]
            if(q !== undefined){
                setQuantity(parseInt(q.cantidad))
                setWasBought(true)
            }
        }
    }, [detail])

    const subtractCart = (e) => {
        if(quantity > 1){
            setQuantity(quantity - 1)
        }
        e.preventDefault();
    }

    const sumCart = (e) => {
        setQuantity(quantity + 1)
        e.preventDefault();
    }

    const addCart = (e) => {
        dispatch(addToCart(cookies.cookieId, props.data.id, quantity));
        e.preventDefault();
    }

    const handleInput = (event) => {
        setQuantity(event.target.value);
    };

    const changeRating = ( newRating, name ) => {
        console.log(newRating)
    }

    const shortDescription = () => {
        return (props.data.descripcion.length > 110) ? props.data.descripcion.substring(0,110)+"..." : props.data.descripcion;
    }

    return (
        <div className="preview-product">
            <Row>
                <Col sm={9}>
                    <Link to={`/article/${props.data.id}`} className='imagePreviewProduct' style={{ backgroundImage:  `url(${props.data.url_imagen.thumb})` }}></Link>
                    
                </Col>
                <Col sm={3}>
                    {false && <a href="#" className="icon-button icon-likeit"></a>}
                    <Link to={`/article/${props.data.id}`} className='icon-button icon-watch'></Link>
                    <a href="#" className="icon-button icon-buy" onClick={addCart}></a>
                </Col>
            </Row>

            { false &&
                <div className="text-center">
                    <StarRatings
                        rating={2}
                        starRatedColor="#006F21"
                        starHoverColor="#006F21"
                        changeRating={changeRating}
                        numberOfStars={5}
                        name='rating'
                        starDimension="20px"
                        starSpacing="2px"
                        />
                </div>
            }


            <div className="title mt-3">
                {props.data.nombre}
            </div>
            <div className="description">
                {props.data.descripcion}
            </div>

            <div className="d-flex justify-content-between mt-3 mb-0 buy">
                <div>
                    <a href="#" className="increase-decrease" onClick={subtractCart}>-</a> 
                    &nbsp;<input type="text" onChange={handleInput} value={quantity} disabled />&nbsp;
                    <a href="#" className="increase-decrease" onClick={sumCart}>+</a>
                </div>
                <div>$ {props.data.precio}</div>
            </div>
            <div className="d-flex justify-content-center mt-3 mb-0 buy">
                <a href="#" className="btn btn-success btn-lg" onClick={addCart}>{wasBought ? 'Actualizar' : 'Comprar'}</a>
            </div>
        </div>
    )
}