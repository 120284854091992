import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Image, Row, Col } from 'react-bootstrap';
import logo from '../img/weeding-planner-logo-web.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import StarRatings from 'react-star-ratings';
import { getArticleAPI } from '../hooks/articles';
import { useCookies } from 'react-cookie';
import { addCartAPI } from '../hooks/cart';
import { CheckCart } from '../components/CheckCart';
import { CartDetails } from '../components/CartDetails';
//redux
import { addToCart } from '../store/slices/cart';
import { useDispatch, useSelector } from 'react-redux';

export const Article = () => {
    const {id} = useParams();
    const {detail} = useSelector(state => state.cart);
    const dispatch = useDispatch();

    const [cookies] = useCookies(['cookieId']);
    const [quantity, setQuantity] = useState(1);
    const [article, setArticle] = useState({});
    const [galery, setGalery] = useState([]);
    const [html, setHtml] = useState("");
    const [wasBought, setWasBought] = useState(false);

    useEffect(() => {
        getArticle(id);
    }, [])

    useEffect(() => {
        if(detail.articles && detail.articles.length > 0){
            const q = detail.articles.filter(article => article.articulo_id == id)[0]
            if(q !== undefined){
                setQuantity(parseInt(q.cantidad))
                setWasBought(true)
            }
        }
    }, [detail])
  
    const getArticle = async (id) => {
        const resp = await getArticleAPI(id);
        let images = []

        if(resp.url_imagen && resp.url_imagen.main){
            images.push(resp.url_imagen);
        }

        if(resp.url_album_imagen.length > 0){
            images = images.concat(resp.url_album_imagen);
        }

        setGalery(images);
        
        const doc = new DOMParser().parseFromString(resp.html, "text/html");
        setHtml(doc.documentElement.textContent)
        //setHtml("<p>hola <strong>Ivan</strong></p>")

        setArticle( resp );
    }

    const subtractCart = (e) => {
        if(quantity > 1){
            setQuantity(quantity - 1)
        }
        e.preventDefault();
    }

    const sumCart = (e) => {
        setQuantity(quantity + 1)
        e.preventDefault();
    }

    const addCart = async (e) => {
        dispatch(addToCart(cookies.cookieId, article.id, quantity));
        e.preventDefault();
    }

    const handleInput = (event) => {
        setQuantity(event.target.value);
    };

    const changeRating = ( newRating, name ) => {
        console.log(newRating)
      }
    
    const albumImagenes = galery && galery.map((image, index) =>
        <div key={index}>
            <img src={`${image.main}`} className="img-fluid" />
        </div>
    );
    return (
        <>
            <Container fluid>
                <div className="row single-header mb-3">
                    <Navbar className="align-items-start">
                        <Link to="/" className='navbar-brand'>
                            <Image src={logo} width={250} className="d-inline-block align-top" fluid />
                        </Link>
                        <Navbar.Toggle />
                        <CartDetails />
                    </Navbar>
                </div>
                <Container className="article">
                    <Row>
                        <Col sm={6}>
                            {galery &&  
                                <Carousel 
                                autoPlay={true}
                                showArrows={true}
                                showStatus={false}
                                className="carousel-modified">
                                {albumImagenes}                               
                            </Carousel>
                            }
                            <div dangerouslySetInnerHTML={{ __html: html }}></div>
                        </Col>
                        <Col sm={6}>
                            <div className="d-flex justify-content-between">
                                <div className="title">{article.nombre}</div>
                                { false &&
                                    <div className="">
                                        <StarRatings
                                            rating={2}
                                            starRatedColor="#006F21"
                                            starHoverColor="#006F21"
                                            changeRating={changeRating}
                                            numberOfStars={5}
                                            name='rating'
                                            starDimension="20px"
                                            starSpacing="2px"
                                            />
                                    </div>
                                }

                            </div>
                            <div className="d-flex justify-content-between mb-3">
                                {(article.codigo && article.codigo.length > 0) && 
                                    <div className="code">Código: {article.codigo}</div>
                                }
                                <div>
                                    <a href="#" className="icon-button icon-buy" onClick={addCart}></a>                                    
                                    { false && <a href="#" className="icon-button icon-likeit"></a>}
                                </div>
                            </div>
                            {(article.precio && article.precio.length > 0) && 
                                <div className="price mb-3">${article.precio}</div>
                            }
                            {(article.descripcion && article.descripcion.length > 0) && 
                                <>
                                    <div><b>Descripcion:</b></div>
                                    <p className="description mb-3">{article.descripcion}</p>
                                </>
                            }
                            {(article.stock && article.stock.length > 0) && 
                                <div className="stock mb-3">Stock: {article.stock}</div>
                            }
                            

                            <div className="d-flex justify-content-evenly mb-3">
                                <div className="buy">Cantidad &nbsp;
                                    <a href="#" className="increase-decrease" onClick={subtractCart}>-</a>
                                    &nbsp;<input type="text" onChange={handleInput} value={quantity} disabled/> &nbsp;
                                    <a href="#" className="increase-decrease" onClick={sumCart}>+</a>
                                </div>
                                <div className="buy"><a href="#" onClick={addCart}>{wasBought ? 'Actualizar Compra' : 'Realizar Compra'}</a></div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </Container>        
        </>
    )
}