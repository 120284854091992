import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import './css/icons.css';
import './css/forms.css';
import './css/article.css';
import './css/carousel.css';
import {App} from './App';

ReactDOM.render(
  <App />,
  document.getElementById('root')
)