import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Image, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import mastercardImage from '../img/tarjeta-master-card.png'; 
import visaImage from '../img/tarjeta-visa.png'; 

export const Footer = () => {
    return (
        <>
        { true && 
            <div className="footer" style={{ minHeight: '20vh', background: '#000000' }}>
                <Container>
                    <Row>
                        <Col>
                            CONTACTO
                            <div><a href='https://api.whatsapp.com/send?phone=+5491125263028'  className='whiteLink'>Whatsapp +54 9 11 2526-3028</a></div>
                            <div><a href="mailto:ventas@weedingplanner.ar" target={'_blank'} className='whiteLink'>ventas@weedingplanner.ar</a></div>
                        </Col>
                        <Col>
                            SECCIONES:<br />
                            <Link to="/home" className='whiteLink'>Inicio</Link><br />
                            <Link to="/shop" className='whiteLink'>Tienda</Link><br />
                            <Link to="/home#form" className='whiteLink'>Contacto</Link>
                        </Col>
                        <Col>
                            INFORMACION:<br />
                            { false && 
                            <>Políticas de privacidad<br />
                            Términos y condiciones<br /> 
                            </>
                            }
                            Envíos a CABA y GBA<br />
                        </Col>
                        <Col>
                            MEDIOS DE PAGO
                            <div>
                                <Image src={mastercardImage} width="70"/>
                                <Image src={visaImage} width="70"/>
                            </div>
                        </Col>
                        <Col>REDES SOCIALES<br />
                            <a href="https://www.instagram.com/weedingplannergrowshop/" target={'_blank'} className='whiteLink'>Instagram</a><br /></Col>
                    </Row>
                </Container>
            </div>
        }
        </>
    )
}