import axios from 'axios';

export const checkCartIdAPI = async (cookie_hash) => {
    try{
        // axios.defaults.baseURL = 'https://weedingplanner.ar/api/';
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        let params = new URLSearchParams();
        params.append('cookie_value', cookie_hash);
        const resp = await axios.post('cart/', params);

        return resp.data;
    } catch (e) {
        console.log(e)
    }
}

export const addCartAPI = async (data) => {
    try{
        // axios.defaults.baseURL = 'https://weedingplanner.ar/api/';
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        let params = new URLSearchParams();
        params.append('cookie_value', data.cookieId);
        params.append('id', data.id);
        params.append('quantity', data.quantity);
        const resp = await axios.post('cart/add.php', params);

        return resp.data;
    } catch (e) {
        console.log(e)
    }
}

export const cartDetailsAPI = async (cookieId) => {
    try{
        // axios.defaults.baseURL = 'https://weedingplanner.ar/api/';
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        let params = new URLSearchParams();
        params.append('cookie_value', cookieId);
        const resp = await axios.post('cart/details.php', params);

        return resp.data;
    } catch (e) {
        console.log(e)
    }
}

export const finishCartAPI = async (data) => {
    try{
        // axios.defaults.baseURL = 'https://weedingplanner.ar/api/';
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        let params = new URLSearchParams();
        params.append('cookie_value', data.cookieId);
        params.append('name', data.name);
        params.append('email', data.email);
        params.append('area', data.area);
        params.append('phone', data.phone);
        params.append('comment', data.comment);
        const resp = await axios.post('cart/finish.php', params);

        return resp.data;
    } catch (e) {
        console.log(e)
    }
}

export const completedPurchasesAPI = async (id) => {
    try{
        // axios.defaults.baseURL = 'https://weedingplanner.ar/api/';
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        const resp = await axios.post('cart/completed_purchases.php?id='+id);

        return resp.data;
    } catch (e) {
        console.log(e)
    }
}