import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from 'react-bootstrap';

export const PaymentMethods = (props) => {
    return (
        <div>
            <h1>Métodos de pago</h1>
            <div className="d-flex">
                <Card style={{ width: '26rem' }}>
                    <Card.Body>
                        <Card.Title>Tarjeta de crédito/débito</Card.Title>
                        <Card.Text>
                            Desde el enlace que figura debajo podrá realizar el pago con tarjeta de crédito o débito
                        </Card.Text>
                        <Card.Link className="btn btn-primary" href={props.purchase.payment_link} target="_blank">Pagar</Card.Link>
                    </Card.Body>
                </Card>
                <Card style={{ width: '26rem' }}>
                    <Card.Body>
                        <Card.Title>Otros medios de pago</Card.Title>
                        <Card.Text>
                            Comuníquese para conocer nuestros otros medios de pago:<br/>

                            <b>Whatsapp:</b> <a href='https://api.whatsapp.com/send?phone=+5491125263028' className='greenLink'>+54 9 11 2526-3028</a><br/>
                            <b>Email:</b> <a href="mailto:ventas@weedingplanner.ar" target={'_blank'} className='greenLink'>ventas@weedingplanner.ar</a>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

