import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Image, Row } from 'react-bootstrap';
import logo from '../img/weeding-planner-logo-web.png';

export const HomeNormal = () => {
    return (
        <>
            <div className="header-home-normal d-none d-sm-block d-xl-none" style={{ minHeight: '100vh' }}>
                { true && (
                <div className="row justify-content-center" style={{ height: '100vh' }}>
                    <div className="col-4 d-flex align-items-top justify-content-center align-top" style={{ border: '0px solid' }}>
                        <div style={{ padding: '20px 20%' }}>
                        <Image src={logo} fluid />
                        </div>
                    </div>
                    <div className="col-4 d-flex align-items-top justify-content-center" style={{ border: '0px solid' }}>
                    </div>
                    <div className="col-4 d-flex align-items-center" style={{ border: '0px solid' }}>
                        
                    </div>
                </div>
                )}
            </div>
        </>
    )
}