import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row } from 'react-bootstrap';
import { PreviewProduct } from './PreviewProduct';
import { getHighlightedArticlesAPI } from '../hooks/articles';

export const HighlightedProducts = () => {
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    getHighlightedArticles();
  }, [])

  const getHighlightedArticles = async () => {
      const resp = await getHighlightedArticlesAPI();
      setArticles( resp );
  }

  const productsHome = articles && articles.map((article, index) =>
    <Col sm={12} md={6} lg={4} xl={3} key={index}>
      <PreviewProduct data={article} />
    </Col>
  );

    return (
        <div>
            <Row>
            {productsHome}
            </Row>
        </div>
    )
}

