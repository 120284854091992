import React, {useState, useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Image, Row, Col } from 'react-bootstrap';
import logo from '../img/weeding-planner-logo-web.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { getDocumentAPI } from '../hooks/documents';
import { CartDetails } from '../components/CartDetails';

export const Document = () => {
    const {id} = useParams();

    const [document, setDocument] = useState({});
    const [galery, setGalery] = useState([]);
    const [html, setHtml] = useState("");

    useEffect(() => {
        getDocument(id);
    }, [])

  
    const getDocument = async (id) => {
        const resp = await getDocumentAPI(id);
        let images = []

        if(resp.url_imagen && resp.url_imagen.main){
            images.push(resp.url_imagen);
        }

        if(resp.url_album_imagen.length > 0){
            images = images.concat(resp.url_album_imagen);
        }

        setGalery(images);
        
        const doc = new DOMParser().parseFromString(resp.html, "text/html");
        setHtml(doc.documentElement.textContent)


        setDocument( resp );
    }

    const albumImagenes = galery && galery.map((image, index) =>
        <div key={index}>
            <img src={`${image.main}`} className="img-fluid" />
        </div>
    );
    return (
        <>
            <Container fluid>
                <div className="row single-header mb-3">
                    <Navbar className="align-items-start">
                        <Link to="/" className='navbar-brand'>
                            <Image src={logo} width={250} className="d-inline-block align-top" fluid />
                        </Link>
                        <Navbar.Toggle />
                        <CartDetails />
                    </Navbar>
                </div>
                <Container className="article">
                    <Row>
                        <Col sm={6}>
                            <div className="d-flex justify-content-between">
                                <div className="title">{document.nombre}</div>
                            </div>

                            {(document.descripcion && document.descripcion.length > 0) && 
                                <>
                                    <div><b>Descripcion:</b></div>
                                    <p className="description mb-3">{document.descripcion}</p>
                                </>
                            }
                            
                        </Col>

                        <Col sm={6}>
                            {galery &&  
                                <Carousel 
                                autoPlay={true}
                                showArrows={true}
                                showStatus={false}
                                className="carousel-modified">
                                {albumImagenes}                               
                            </Carousel>
                            }
                            <div dangerouslySetInnerHTML={{ __html: html }}></div>
                        </Col>
                    </Row>
                </Container>
            </Container>        
        </>
    )
}