import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Container, Image, Row } from 'react-bootstrap';
import { HowToBuy } from '../components/HowToBuy';
import { LastNews } from '../components/LastNews';
import { HighlightedProducts } from '../components/HighlightedProducts';
import { ContactForm } from '../components/ContactForm';
import { HomeMobile } from './HomeMobile';
import { HomeWide } from './HomeWide';
import { HomeNormal } from './HomeNormal';
import { CartDetails } from '../components/CartDetails';

export const Home = () => {

    return (
        <>
            <HomeMobile />
            <HomeNormal />
            <HomeWide />

            <Container fluid>
                <div className="row shop-separator">
                <div className="col-1">
                </div>
                <div className="col-6 d-flex flex-column" >
                    <div className="title">Tienda</div>
                    <div>Tenemos una amplia varidad de productos. Acceda nuestro completo listado desde el siguiente enlace.</div>
                    <div className='mt-3'><Link to="/shop" className='btn btn-success btn-lg'>Nuestros productos</Link></div>
                </div>
                <div className="col-5">
                </div>
                </div>

                <div className="mt-5 mb-5">
                <h1 className="title-with-separator"><span>Productos destacados</span></h1>
                <Navbar className="align-items-start">
                    <CartDetails />
                </Navbar>
                <HighlightedProducts />
                </div>

                {false &&
                <>
                    <div className="mt-5 mb-5">
                    <h1 className="title-with-separator"><span>Cómo comprar ?</span></h1>
                    <HowToBuy />
                    </div>
                    </>
                }                

                <div className="mt-5 mb-5">
                    <h1 className="title-with-separator"><span>Últimas publicaciones</span></h1>
                    <LastNews />
                </div>

                <div className="mt-5 mb-5">
                <h1 className="title-with-separator"><span>Comunicate con nosotros</span></h1>
                <ContactForm />
                </div>

            </Container>
        </>
    )
}