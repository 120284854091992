import axios from 'axios';

export const sendContactFormAPI = async (data) => {
    try{
        // axios.defaults.baseURL = 'https://weedingplanner.ar/api/';
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        let params = new URLSearchParams();
        params.append('name', data.name);
        params.append('email', data.email);
        params.append('area', data.area);
        params.append('phone', data.phone);
        params.append('locale', data.locale);
        params.append('comment', data.comment);
        const resp = await axios.post('contact_form.php', params);

        return resp.data;
    } catch (e) {
        console.log(e)
    }
}
