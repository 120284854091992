import axios from 'axios';

export const getArticlesAPI = async () => {
    try{
        // https://reqres.in/
        // axios.defaults.baseURL = 'https://weedingplanner.ar/api/';
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        const resp = await axios.get('articulos/');

        return resp.data;
    } catch (e) {
        console.log(e)
    }
}

export const getArticleAPI = async (id) => {
    try{
        // axios.defaults.baseURL = 'https://weedingplanner.ar/api/';
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        const resp = await axios.get('articulos/get.php?id='+id);

        return resp.data;
    } catch (e) {
        console.log(e)
    }
}

export const getHighlightedArticlesAPI = async () => {
    try{
        // axios.defaults.baseURL = 'https://weedingplanner.ar/api/';
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        const resp = await axios.get('articulos/destacados.php');

        return resp.data;
    } catch (e) {
        console.log(e)
    }
}

export const getCategoriesAPI = async () => {
    try{
        // axios.defaults.baseURL = 'https://weedingplanner.ar/api/';
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        const resp = await axios.get('articulos/rubros.php');

        return resp.data;
    } catch (e) {
        console.log(e)
    }
}

export const searchArticleAPI = async (query) => {
    try{
        axios.defaults.baseURL = process.env.REACT_APP_API_URL
        const resp = await axios.get('articulos/buscar.php?q='+query);

        return resp.data;
    } catch (e) {
        console.log(e)
    }
}