import React, {useState} from 'react';
import { Link } from "react-router-dom";

export const PreviewNews = (props) => {

    return (
        <div className="preview-news">

            <Link to={`/document/${props.data.id}`}>
                <div className="imageNews" style={{ backgroundImage:  `url(${props.data.url_imagen.thumb})` }}></div>
                {
                    false &&
                    <div className="d-flex justify-content-between">
                        <div>4/5/201</div>
                        <div>comentarios</div>
                    </div>

                }
                <div className="title mt-3">
                    {props.data.nombre}
                </div>
                <div className="description">
                    {props.data.descripcion}
                </div>
            </Link>
        </div>
    )
}