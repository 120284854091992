import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Image, Row, Col, Form, ListGroup } from 'react-bootstrap';
import {ProductsList} from '../components/ProductsList';
import { getCategoriesAPI, getArticlesAPI, searchArticleAPI } from '../hooks/articles';
import logo from '../img/weeding-planner-logo-web.png';
import { CartDetails } from '../components/CartDetails';
// redux
import { setSections, setCategories, setArticles } from '../store/slices/cart';
import { useDispatch, useSelector } from 'react-redux';

export const Shop = () => {
    const dispatch = useDispatch();
    const {sections, categories} = useSelector(state => state.cart);

    useEffect(() => {
        getSections();
        getArticles();
    }, [])

    const getSections = async () => {
        const resp = await getCategoriesAPI();
        dispatch( setSections( resp.map(c => ({id: c.id, nombre: c.nombre, selected: false})) ) );
    };

    const getArticles = async () => {
        const resp = await getArticlesAPI();
        dispatch( setArticles( resp ) );
    }

    const searchArticle = async (query) => {
        const resp = await searchArticleAPI(query);
        dispatch( setArticles( resp ) );
    };

    const handleChangeSections = (e) => {
        const newSections = sections.map(item => {
            if(item.id == e.target.value) {
                return {...item, selected: e.target.checked}
            }
            return item
        })
        dispatch( setSections( newSections ) );
    };

    const handleChangeCategories = (e) => {
        const newCategories = categories.map(item => {
            if(item.id == e.target.value) {
                return {...item, selected: e.target.checked}
            }
            return item
        })
        dispatch( setCategories( newCategories ) );
    };

    const handleChange = (e) => {
        //setQuery(e.target.value)
        searchArticle(e.target.value);
    }

    const listSections = sections && sections.map((section, index) => 
        <ListGroup.Item key={index}>
            <Form.Group className="b-0" controlId={"checkboxRubro"+index}>
                <Form.Check onChange={handleChangeSections} value={section.id} type="checkbox" label={section.nombre} />
                {section.children && section.children.id_children && 
                    <ListGroup.Item>
                        <Form.Group className="b-0" controlId={"checkboxSubRubro"+index}>
                            <Form.Check type="checkbox" label={section.children.nombre_children} />
                        </Form.Group>
                    </ListGroup.Item>
                }
            </Form.Group>
        </ListGroup.Item>
    );

    const listCategories = categories && categories.map((category, index) => 
        <ListGroup.Item key={index}>
            <Form.Group className="b-0" controlId={"checkboxVariante"+index}>
                <Form.Check onChange={handleChangeCategories} value={category.id} type="checkbox" label={category.name} />
            </Form.Group>        
        </ListGroup.Item>
    );

    return (
        <>
            <Container fluid>
                <div className="row shop-separator mb-3">
                    <Navbar className="align-items-start">
                        <Link to="/" className='navbar-brand'>
                            <Image src={logo} width={250} className="d-inline-block align-top" fluid/>
                        </Link>{' '}
                        <div className="title">Tienda</div>
                        <Navbar.Toggle />
                        <CartDetails />
                    </Navbar>
                </div>

                <Row>
                    <Col sm={3}>
                        <Form.Group className="mb-3">
                            <Form.Control type="text" onChange={handleChange} placeholder="Buscar producto..." />
                        </Form.Group>
                        <div className="little-title">Etapa de vida</div>
                        <ListGroup variant="flush">
                            {listCategories}
                        </ListGroup>
                        <div className="little-title">Rubro</div>
                        <ListGroup variant="flush">
                            {listSections}
                        </ListGroup>
                    </Col>
                    <Col sm={9}>
                        <ProductsList />
                    </Col>
                </Row>
            </Container>        
        </>
    )
}