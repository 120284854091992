import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { Soon } from './pages/Soon';
import { Home } from './pages/Home';
import { Shop } from './pages/Shop';
import { PurchaseDetail } from './pages/PurchaseDetail';
import { PurchaseClient } from './pages/PurchaseClient';
import { Article } from './pages/Article';
import { Document } from './pages/Document';
import { Footer } from './components/Footer';
import { Provider } from 'react-redux';
import store from './store';
import { CheckCart } from './components/CheckCart';

export const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <CheckCart />
        <Container fluid className="p-0">
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/home" exact>
              <Home />
            </Route>
            <Route path="/shop">
              <Shop />
            </Route>
            <Route path="/article/:id">
              <Article />
            </Route>
            <Route path="/purchase_detail">
              <PurchaseDetail />
            </Route>
            <Route path="/purchase/:id">
              <PurchaseClient />
            </Route>
            <Route path="/purchase_admin">
              <PurchaseDetail />
            </Route>
            <Route path="/document/:id">
              <Document />
            </Route>
          </Switch>
          <Footer />
        </Container>
      </Router>
    </Provider>
  );
}
